import React from "react";
import { Link } from "gatsby";

//images
import footer_optosweden_logo from "../images/optosweden-rgb.svg";
import footer_crediflow_logo from "../images/crediflow-rgb.svg";

import aaa_logo from "../images/certifieringar/aaa_logo.svg";
import peppol_logo from "../images/certifieringar/peppol.png";
import alaris_logo from "../images/certifieringar/alaris.png";

const currentTime = new Date()

function Footer(){

    return(
        <footer className="bg-gradient-to-br from-blue-custome-footer via-blue-custome-footer to-blue-custome">
            <div className="container mx-auto px-4 xl:px-24">
                <div className="py-24 grid md:grid-cols-2 lg:grid-cols-4">
                    <div className="text-white border-b py-14 lg:border-b-0 border-gray-600">
                        <div className="w-40 mx-auto flex flex-col">
                            <h2 className="mb-10 font-semibold text-2xl">Kontakta oss</h2>
                            <p className="mt-4">
                                <span className="inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-4 w-4" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                    </svg>
                                </span>
                                <span className="ml-2">08-578 604 00</span>
                            </p>
                            <p className="mt-4">
                                <span className="inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-4 w-4" viewBox="0 0 16 16">
                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                                    </svg>
                                </span>
                                <span className="ml-2">info@crediflow.se</span>
                            </p>
                        </div>   
                    </div>

                    <div className="text-white border-b py-14 lg:border-b-0 border-gray-600">
                        <h2 className="text-white mb-10 font-semibold text-2xl text-center">Koncernen</h2>
                        <div className="w-4/6 lg:w-5/6 mx-auto">
                            <img className="mt-4" src={footer_crediflow_logo} alt="CrediFlow"/>
                            <img className="mt-4" src={footer_optosweden_logo} alt="OptoSwden"/>
                        </div>
                    </div>

                    <div className="text-white py-14 border-b md:border-b-0 border-gray-600">
                        <h2 className="text-white mb-10 font-semibold text-2xl text-center">Certifieringar</h2>
                        <div className="w-2/6  md:w-3/6 mx-auto">
                            <img className="mt-4" src={aaa_logo} alt="aaa"/>
                            <img className="mt-4" src={peppol_logo} alt="peppol"/>
                            <img className="mt-4" src={alaris_logo} alt="Alaris"/>
                        </div>
                    </div>

                    <div className="text-white py-14">
                        <div className="w-40 mx-auto flex flex-col">
                            <h2 className="mb-10 font-semibold text-2xl">Genvägar</h2>
                            <Link to="/leverantorsfakturor" className="mt-4">Leverantörsfakturor</Link>
                            <Link to="/kundfakturor" className="mt-4">Kundfakturor</Link>
                            <Link to="/edi" className="mt-4 ">EDI</Link>
                            <Link to="/sdk" className="mt-4">SDK</Link>
                            <Link to="/support" className="mt-4">Support</Link>
                            <Link to="/partner" className="mt-4">Partner</Link>
                            <Link to="/nyheter" className="mt-4">Nyheter</Link>
                            <Link to="/webinar" className="mt-4">Webinar</Link>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-white p-4 text-center flex items-center justify-center">           
                <span className="inline-block text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-4 w-4 mr-1" viewBox="0 0 1024 1024">
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm5.6-532.7c53 0 89 33.8 93 83.4.3 4.2 3.8 7.4 8 7.4h56.7c2.6 0 4.7-2.1 4.7-4.7 0-86.7-68.4-147.4-162.7-147.4C407.4 290 344 364.2 344 486.8v52.3C344 660.8 407.4 734 517.3 734c94 0 162.7-58.8 162.7-141.4 0-2.6-2.1-4.7-4.7-4.7h-56.8c-4.2 0-7.6 3.2-8 7.3-4.2 46.1-40.1 77.8-93 77.8-65.3 0-102.1-47.9-102.1-133.6v-52.6c.1-87 37-135.5 102.2-135.5z"/>
                    </svg>
                </span>
                {currentTime.getFullYear()} Crediflow
            </p>
      </footer>
    );
}
export default Footer;