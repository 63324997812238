import React from "react";
import { Link } from "gatsby";
import './navbar.css';
import { IoMdArrowDropdown } from 'react-icons/io'; //dropdown arrow icon

class Dropdown extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            should_open: false,
        }

        this.handle_click = this.handle_click.bind(this);
        this.handle_hover = this.handle_hover.bind(this);
        this.close_dropdown = this.close_dropdown.bind(this);
    }

    // open and close dropdown with click
    handle_click(event) {
        event.preventDefault();
        if (this.state.should_open === false) {
            this.setState({ should_open: true })
        }
        else if (this.state.should_open === true) {
            this.setState({ should_open: false })
        }
    }

    // open dropdown with hover
    handle_hover(event) {
        event.preventDefault();
        if (window.innerWidth >= 992 && this.state.should_open === false) {
            this.setState({ should_open: true })
        }
    }
    
    // close dropdown with hover
    close_dropdown(event) {
        event.preventDefault();
        if (window.innerWidth >= 992){
            this.setState({ should_open: false })
        }
    }

    render(){       
        return(
            <div role="none" onMouseEnter={this.handle_hover} onMouseLeave={this.close_dropdown} className="flex flex-col items-center justify-start">
                <button onClick={this.handle_click} 
                    className={this.props.textcolor === "white" ?
                        "text-white cursor-pointer mb-6 lg:mb-0 ml-0  lg:ml-6  flex flex-row items-center"
                        :  
                        "text-gray-700 cursor-pointer mb-6 lg:mb-0 ml-0  lg:ml-6  flex flex-row items-center"
                    }
                >
                    <span>{this.props.text}</span> 
                    <span>
                        <IoMdArrowDropdown 
                            className={this.state.should_open 
                                ?
                                "transition duration-500 ease-in-out transform rotate-180"
                                :""
                            }
                        />
                    </span>
                </button>
                {this.state.should_open 
                    ?
                    <div className=" cursor-pointer lg:mt-6 lg:absolute lg:z-10 lg:pt-9 mb-6 lg:ml-6 transition duration-300 ease-in-out transform dropdown_animation">
                        { this.props.children }
                    </div>
                    :""
                }
            </div>  
        );
    }
}
export default Dropdown;



export function DropdownItem(props){
    return(
        <Link to={props.href} className="w-full border border-gray-100 lg:rounded-lg px-4 lg:px-2 py-2 lg:py-4 bg-white hover:bg-blue-custome lg:hover:bg-white text-gray-700 hover:shadow-md hover:text-white lg:hover:text-blue-custome">
                <h3 className="lg:font-semibold">{props.name}</h3>
                <p className="hidden lg:block text-gray-600 text-sm mt-2">{props.text}</p>
        </Link>
    );
}