import React from "react";
import Cookies from "./cookies/cookies";
import Footer from "./footer";
import Navbar from './navbar/navbar';
import UniversalCookie from 'universal-cookie';
import "./layout.css";



class Layout extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      city: "",
      region: "",
      country_name: "",
      country_area: ""
    }
  }

  componentDidMount(){
    
    if(sessionStorage.getItem("has_session") !== "true"){
  
      const Cookie_library = new UniversalCookie();
      
      //Get user location
      fetch('https://ipapi.co/json')
      .then(res => res.json())
      .then( json => {
          console.log(json);
          this.setState({
            city: json.city,
            region: json.region,
            country_name: json.country_name,
            country_area: json.country_area
          })

          //Send user location to database
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              city: this.state.city,
              region: this.state.region,
              country_name: this.state.country_name
            })
          };
          fetch('https://crediflow.se/admin/api/statistic.php', requestOptions);
          sessionStorage.setItem("session_time", true);

          if(Cookie_library.get("CookieAlert") === "false"){
            const currentYear = new Date();
            const nextYear = new Date();
            nextYear.setFullYear(currentYear.getFullYear() + 1);

            switch(Cookie_library.get("CookieConsent")) {
              case "all":
                Cookie_library.set("last_session", currentYear, {
                path: '/',
                expires: nextYear,
              });
                Cookie_library.set("country_area", this.state.country_area, {
                  path: '/',
                  expires: nextYear,
              });
                break;

              case "settings":
                Cookie_library.set("last_session", currentYear, {
                path: '/',
                expires: nextYear,
              });
                break;

              case "statistic":
                Cookie_library.set("country_area", this.state.country_area, {
                path: '/',
                expires: nextYear,
              });
                break;

              default:
                break;
            } 
          }
          

      }) 
      
    }
  }
  
  render(){
    return (
      <div style={{overflowX: 'hidden'}}>
        <main>
          <Navbar />
          {this.props.children}
          <Footer />
          <Cookies/>
        </main>
      </div>
    );
  }
}

export default Layout;

