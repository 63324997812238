import React from 'react';
import './navbar.css';
import { Link } from 'gatsby';
import Dropdown, { DropdownItem } from "./dropdown";
import Language from "./language";
import { CgMenu, CgClose } from 'react-icons/cg'; //hamburger menu icon and X icon

//company logo
import logo from "../../images/crediflow-rgb.svg";
import en_logo from "../../images/en.svg";
import sv_logo from "../../images/sv.svg";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navbar_active: false,
            mobile_menu: false,
        }

        this.mobile_menu = this.mobile_menu.bind(this);
        this.navbar_active = this.navbar_active.bind(this);
    }

    //open and close mobile menu
    mobile_menu(event) {
        event.preventDefault();
        if (this.state.mobile_menu === false) {
            this.setState({ mobile_menu: true })
        }
        else if (this.state.mobile_menu === true) {
            this.setState({ mobile_menu: false })
        }
    }

    //Change nav background if scrolled
    navbar_active() {
        if (window.scrollY >= 5) {
            this.setState({ navbar_active: true });
        } else {
            this.setState({ navbar_active: false });
        }
    }

    render() {
        //Event listener for scrolling
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', this.navbar_active)
        }

        return (
            <div style={{ height: '80px' }}>
                <nav className="navbar" id={this.state.navbar_active ? 'scroll_active' : 'scroll_disable'}>
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="items_wrapper">
                            <Link to="/">
                                <img className="h-20 py-3 xl:py-2 z-50 hover:opacity-90" src={logo} alt="Crediflow"/>
                            </Link>

                            <div className={this.state.mobile_menu ? 'items active' : 'items'}>
                                <Link to='/' className="mb-6 lg:mb-0 text-gray-700 hover:text-blue-custome flex items-center">Hem</Link>
                                <Dropdown text="Tjänster">
                                    <div className="w-full 2xl:w-128 flex flex-col lg:grid xl:grid-cols-2 2xl:grid-cols-4 gap-2 bg-gray-100 lg:bg-white p-4 lg:shadow-lg rounded-lg">
                                        <DropdownItem href="/leverantorsfakturor" name="Leverantörsfakturor" text="Ta emot fakturor oavsett format..."/>
                                        <DropdownItem href="/kundfakturor" name="Kundfakturor" text="Skicka som din kund önskar..."/>
                                        <DropdownItem href="/edi" name="EDI" text="Helhetslösning för ert EDI-flöde..."/>
                                        <DropdownItem href="/sdk" name="SDK" text="SDK (Säker digital kommunikation) är en..."/>
                                    </div>
                                </Dropdown>
                                <Link to='/support' className="mb-6 ml-0 lg:mb-0 lg:ml-6 text-gray-700 hover:text-blue-custome">Support</Link>
                                <Link to='/partner' className="mb-6 ml-0 lg:mb-0 lg:ml-6 text-gray-700 hover:text-blue-custome">Partner</Link>
                                <Link to='/om-oss' className="mb-6 ml-0 lg:mb-0 lg:ml-6 text-gray-700 hover:text-blue-custome">Om oss</Link>
                                <Dropdown text="Kunskap">
                                    <div className="w-full xl:w-80 flex flex-col lg:grid xl:grid-cols-2 gap-2 bg-gray-100 lg:bg-white p-4 lg:shadow-lg rounded-lg">
                                        <DropdownItem href="/nyheter" name="Nyheter" text="Senaste nyheterna från Crediflow..."/>
                                        <DropdownItem href="/webinar" name="Webinar" text="Vill du vara med på ett live webinar..."/>
                                    </div>
                                </Dropdown>

                             

                                <Link to='/kundportal' className="mb-6 ml-0 lg:mb-0 lg:ml-6 px-8 lg:px-4 xl:px-8  py-2 border border-blue-custome text-blue-custome rounded-full hover:bg-blue-custome hover:opacity-90 hover:text-white">
                                    Logga In
                                </Link>

                                <Link to='/demo' className="ml-0 lg:ml-3 px-6 lg:px-3 xl:px-6 py-2 border border-blue-custome bg-blue-custome text-white rounded-full hover:opacity-90 shadow-lg">
                                    Boka Demo
                                </Link>

                                <Language>
                                    <div className="mt-4 lg:mt-0 w-full flex flex-col items-start justify-start gap-2 bg-gray-100 lg:bg-white p-2 lg:shadow-lg rounded-lg">
                                        <a href="https://crediflow.com/" className="w-full flex items-center text-gray-700 hover:text-blue-custome">
                                            <img src={en_logo} alt="EN" className="h-8 w-8 shadow-md rounded-full p-px"/>
                                            <span className='pl-2'>English</span>
                                        </a>
                                        <a href="https://crediflow.se/" className="w-full flex  items-center text-gray-700 hover:text-blue-custome">
                                            <img src={sv_logo} alt="SV" className="h-8 w-8 shadow-md rounded-full p-px"/> 
                                            <span className='pl-2'>Svenska</span>
                                        </a>
                                    </div>   
                                </Language>
                            </div>
                                           
                            <button className='block lg:hidden text-4xl text-black-custome hover:text-blue-custome z-50' onClick={this.mobile_menu} onKeyDown={this.mobile_menu} >
                                {this.state.mobile_menu ? <CgClose /> : <CgMenu />}
                            </button>
                        </div> 
                    </div>
                </nav>
            </div>
        );
    }
}
export default Navbar;

